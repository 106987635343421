import React, { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const ImportJobImportJob = lazy(() => import('./importJob/ImportJob'));
const ImportJobsImportJobs = lazy(() => import('./importJobs/ImportJobs'));

const ImportJobsAppConfig = {
	settings: {
		layout: {},
	},
	auth: authRoles.ameroAdmin,
	routes: [
		{
			path: '/apps/import-jobs/:importJobUid',
			element: <ImportJobImportJob />,
		},
		{
			path: '/apps/import-jobs',
			element: <ImportJobsImportJobs />,
		},
	],
};

export default ImportJobsAppConfig;
