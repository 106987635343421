import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import React, { useEffect, useMemo, useState } from 'react';
import { Tab as TabType, Tabs as TabTypes, Buttons as ButtonTypes } from './TabPageTypes';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// @ts-ignore
import FuseUtils from '@ameroservices-platform/shared/fuse/utils';

const TabPage = React.forwardRef<never, never>(
	(
		{
			wrappedFusePageCarded: WrappedFusePageCarded,
			tabs,
			buttons,
			contentClassName,
			defaultTab,
			defaultContent,
			alerts: Alerts,
			useUrlNavigation,
			...props
		}: {
			wrappedFusePageCarded: typeof React.Component;
			defaultContent?: typeof React.Component;
			alerts?: typeof React.Component;
			tabs: TabTypes;
			buttons: ButtonTypes;
			contentClassName: string;
			defaultTab?: string;
			useUrlNavigation?: boolean;
		},
		ref
	) => {
		const [searchParams, setSearchParams] = useSearchParams();
		const navigate = useNavigate();
		const { search, state } = useLocation();
		const forcedTab = state?.tab || searchParams.get('tab');
		// @ts-ignore
		const userRole = useSelector(({ auth }) => auth.user.role);
		const isAmeroAdmin = useMemo(() => FuseUtils.hasPermission(['ameroAdmin'], userRole), [userRole]);

		const orderedTabs = useMemo(() => {
			let newTabs: Record<string, TabType> = {};
			tabs.forEach(tab => {
				newTabs[tab.title] = tab;
			});
			let values = Object.values(newTabs);
			newTabs = {};
			values.forEach(tab => {
				newTabs[tab.order] = tab;
			});
			values = Object.values(newTabs);
			values.sort((a, b) => a.order - b.order);
			return values.filter(tab => (!tab.isAmeroAdmin || isAmeroAdmin) && !tab.hidden);
		}, [tabs, isAmeroAdmin]);

		const [tabValue, setTabValue] = useState<string | null>(
			forcedTab?.toLowerCase() ||
				defaultTab?.toLowerCase() ||
				(orderedTabs[0] ? orderedTabs[0].title?.replace(/ /g, '-')?.toLowerCase() : null)
		);

		useEffect(() => {
			setTabValue(
				forcedTab?.toLowerCase() ||
					defaultTab?.toLowerCase() ||
					(orderedTabs[0] ? orderedTabs[0].title?.replace(/ /g, '-')?.toLowerCase() : null)
			);
		}, [forcedTab, defaultTab, orderedTabs]);

		function handleChangeTab(event: React.SyntheticEvent, value: string) {
			setTabValue(value);
			if (useUrlNavigation) {
				searchParams.set('tab', value.replace(/ /g, '-').toLowerCase());
				setSearchParams(searchParams);
			}
		}

		const Element = useMemo<JSX.Element | null>(() => {
			const tab = orderedTabs.find(t => t.title.replace(/ /g, '-').toLowerCase() === tabValue?.toLowerCase());
			if (!tab) {
				return null;
			}
			return tab.element;
		}, [orderedTabs, tabValue]);

		return (
			<WrappedFusePageCarded
				auditLog
				{...props}
				ref={ref}
				contentToolbar={
					<Tabs
						value={tabValue}
						onChange={handleChangeTab}
						indicatorColor="primary"
						textColor="primary"
						variant="scrollable"
						scrollButtons="auto"
						classes={{ root: 'w-full h-64' }}
					>
						{orderedTabs.map(tab => (
							<Tab
								key={tab.title.toLowerCase()}
								className="h-64 normal-case"
								label={tab.title}
								value={tab.title.replace(/ /g, '-').toLowerCase()}
								disabled={tab.disabled}
							/>
						))}
					</Tabs>
				}
				content={
					<div className={contentClassName}>
						{Alerts ? <Alerts /> : null}
						{Element !== null ? Element : defaultContent || null}
					</div>
				}
				innerScroll
			/>
		);
	}
);

export default TabPage;
