import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableHead from '@mui/material/TableHead';
import FuseScrollbars from '@ameroservices-platform/loppe-backend/fuse/core/FuseScrollbars';
import FuseLoading from '@ameroservices-platform/loppe-backend/fuse/core/FuseLoading';
import { useDispatch, useSelector } from 'react-redux';
import {
	bankFileRecordListener,
	bankFileRecordSettlementOrderLinesListener,
	selectBankFileRecord,
	selectBankFileRecordSettlementOrderLines,
} from '@ameroservices-platform/loppe-backend/app/main/apps/bankPayouts/bankPayoutsSlice';
import BankFileRecordSettlementOrderLinesTableRow from '@ameroservices-platform/loppe-backend/app/main/apps/bankPayouts/bankFile/bankFileRecord/tabs/bankFileRecordSettlementOrderLines/BankFileRecordSettlementOrderLinesTableRow';

const staticTableColumns = [
	{
		id: 'date',
		align: 'left',
		disablePadding: false,
		label: 'Dato',
		sort: false,
	},
	{
		id: 'orderNumber',
		align: 'left',
		disablePadding: false,
		label: 'Ordrenummer',
		sort: false,
	},
	{
		id: 'type',
		align: 'left',
		disablePadding: false,
		label: 'Type',
		sort: false,
	},
	{
		id: 'amount',
		align: 'left',
		disablePadding: false,
		label: 'Beløb',
		sort: false,
	},
	{
		id: 'payoutProcessed',
		align: 'left',
		disablePadding: false,
		label: 'Udbetalingsstatus',
		sort: false,
	},
];

function BankFileRecordSettlementOrderLinesTable() {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [order, setOrder] = useState({
		direction: 'desc',
		id: 'createdDate',
	});

	const routeParams = useParams();
	const dispatch = useDispatch();

	const bankFileRecord = useSelector(selectBankFileRecord);
	const bankFileRecordSettlementOrderLines = useSelector(selectBankFileRecordSettlementOrderLines);

	useEffect(() => {
		const bankFileRecordListenerUnsubFunc = dispatch(
			bankFileRecordListener(routeParams.bankFileUid, routeParams.bankFileRecordUid)
		);
		return () => {
			bankFileRecordListenerUnsubFunc();
		};
	}, [dispatch]);

	useEffect(() => {
		if (bankFileRecord) {
			const bankFileSettlementOrderLinesListenerUnsubFunc = dispatch(
				bankFileRecordSettlementOrderLinesListener(bankFileRecord)
			);
			return () => {
				bankFileSettlementOrderLinesListenerUnsubFunc();
			};
		}
		return () => {};
	}, [dispatch, bankFileRecord]);

	function handleRequestSort(event, property) {
		const id = property;
		let direction = 'desc';

		if (order.id === property && order.direction === 'desc') {
			direction = 'asc';
		}

		setOrder({
			direction,
			id,
		});
	}

	const createSortHandler = (property) => (event) => {
		handleRequestSort(event, property);
	};

	function handleChangePage(event, value) {
		setPage(value);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(event.target.value);
	}

	if (!bankFileRecordSettlementOrderLines) {
		return <FuseLoading />;
	}

	return (
		<div className="w-full flex flex-col">
			<FuseScrollbars className="flex-grow overflow-x-auto">
				<Table aria-label="collapsible table">
					<TableHead className="bg-gray-100">
						<TableRow>
							{staticTableColumns.map((column) => {
								return (
									<TableCell
										key={column.id}
										align={column.align}
										sortDirection={order.id === column.id ? order.direction : false}
									>
										{column.sort ? (
											<Tooltip
												title="Sorter"
												placement={column.align === 'right' ? 'bottom-end' : 'bottom-start'}
												enterDelay={300}
											>
												<TableSortLabel
													active={order.id === column.id}
													direction={order.direction}
													onClick={createSortHandler(column.id)}
												>
													{column.label}
												</TableSortLabel>
											</Tooltip>
										) : (
											column.label
										)}
									</TableCell>
								);
							}, this)}
						</TableRow>
					</TableHead>
					<TableBody>
						{_.orderBy(
							bankFileRecordSettlementOrderLines,
							[
								(o) => {
									return o.bookedDate;
								},
							],
							[order.direction]
						)
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((bankFileRecordSettlementOrderLine, index) => (
								<BankFileRecordSettlementOrderLinesTableRow
									key={index}
									bankFileRecordSettlementOrderLine={bankFileRecordSettlementOrderLine}
								/>
							))}
					</TableBody>
				</Table>
			</FuseScrollbars>

			<TablePagination
				className="overflow-hidden flex-shrink-0"
				component="div"
				count={bankFileRecordSettlementOrderLines.length}
				rowsPerPage={rowsPerPage}
				page={page}
				backIconButtonProps={{
					'aria-label': 'Previous Page',
				}}
				nextIconButtonProps={{
					'aria-label': 'Next Page',
				}}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</div>
	);
}

export default BankFileRecordSettlementOrderLinesTable;
