import React, { useRef } from 'react';
import PageHeader from '@ameroservices-platform/loppe-backend/app/ui-components/PageHeader';
import TabPage from '@ameroservices-platform/shared/ui-components/TabPage/TabPage';
import WrappedFusePageCarded from '@ameroservices-platform/loppe-backend/app/ui-components/WrappedFusePageCarded';
import bankPayoutsReducer from '@ameroservices-platform/loppe-backend/app/main/apps/bankPayouts/bankPayoutsSlice';
import withReducer from '@ameroservices-platform/loppe-backend/app/store/withReducer';
import BankFileRecords from '@ameroservices-platform/loppe-backend/app/main/apps/bankPayouts/bankFile/tabs/bankFileRecords/BankFileRecords';

function BankFile() {
	const pageLayout = useRef(null);

	return (
		<TabPage
			wrappedFusePageCarded={WrappedFusePageCarded}
			ref={pageLayout}
			header={<PageHeader title={'Bankfil'} icon={'description'} goBack />}
			tabs={[
				{
					title: 'Bankfil posteringer',
					element: <BankFileRecords />,
					order: 0,
				},
			]}
			innerScroll
		/>
	);
}

export default withReducer('bankPayoutsApp', bankPayoutsReducer)(BankFile);
