import SharedPageHeader from '@ameroservices-platform/shared/ui-components/PageHeader';
import { useSelector } from 'react-redux';
import { selectMainTheme } from '@ameroservices-platform/loppe-backend/app/store/fuse/settingsSlice';

function PageHeader(props) {
	const mainTheme = useSelector(selectMainTheme);
	return <SharedPageHeader mainTheme={mainTheme} {...props} />;
}

export default PageHeader;
