import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { moneyFormatter } from '@ameroservices-platform/shared/utility/numbers/digits';
import moment from 'moment-timezone';
import { settlementOrderLineTypesTranslate } from '@ameroservices-platform/shared/enums/settlementOrderTypes';
import clsx from 'clsx';

function BankFileRecordSettlementOrderLinesTableRow({ bankFileRecordSettlementOrderLine }) {
	return (
		<TableRow role="checkbox" tabIndex={-1} className={'h-72'}>
			<TableCell className="truncate" component="th" scope="row">
				{bankFileRecordSettlementOrderLine.bookedDate
					? moment(bankFileRecordSettlementOrderLine.bookedDate.toDate()).format('DD-MM-YYYY')
					: '-'}
			</TableCell>
			<TableCell component="td" scope="row">
				{bankFileRecordSettlementOrderLine.orderNumber}
			</TableCell>
			<TableCell component="td" scope="row">
				{
					settlementOrderLineTypesTranslate[bankFileRecordSettlementOrderLine.settlementOrderType][
						bankFileRecordSettlementOrderLine.lineType
					]
				}
			</TableCell>
			<TableCell component="td" scope="row">
				{moneyFormatter.format(bankFileRecordSettlementOrderLine.amount / 100.0)}
			</TableCell>
			<TableCell component="td" scope="row">
				{!bankFileRecordSettlementOrderLine.payoutProcessed && (
					<span className={clsx('inline text-12 p-4 rounded truncate', 'bg-red text-white')}>
						{'Ikke gennemført'}
					</span>
				)}
				{!!bankFileRecordSettlementOrderLine.payoutProcessed && (
					<span className={clsx('inline text-12 p-4 rounded truncate', 'bg-green text-white')}>
						{'Gennemført'}
					</span>
				)}
			</TableCell>
		</TableRow>
	);
}

export default BankFileRecordSettlementOrderLinesTableRow;
