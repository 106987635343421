import layout1 from '@ameroservices-platform/loppe-backend/app/fuse-layouts/layout1/Layout1';
import layout2 from '@ameroservices-platform/loppe-backend/app/fuse-layouts/layout2/Layout2';
import layout3 from '@ameroservices-platform/loppe-backend/app/fuse-layouts/layout3/Layout3';

const FuseLayouts = {
	layout1,
	layout2,
	layout3,
};

export default FuseLayouts;
