import { Navigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

function HomePageNavigationComponent() {
	const flags = useFlags();

	return flags.overviewHomePageOnLoppe ? <Navigate to="apps/overview" /> : <Navigate to="apps/dashboard" />;
}

export default HomePageNavigationComponent;
