import CustomDateRangePicker from '@ameroservices-platform/shared/ui-components/CustomDateRangePicker';
import React, { useState } from 'react';
import moment from 'moment-timezone';
import ReactDOM from 'react-dom';
import Button from '@mui/material/Button';
import { ThemeProvider, useTheme } from '@mui/styles';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';

function PeriodSelectorForOverviewPage({ dateRangeChangedCallback, initialState }) {
	const mainTheme = useTheme();
	const [anchorEl, setAnchorEl] = useState('');
	const [dateRange, setDateRange] = useState([initialState]);

	function handleClose() {
		setAnchorEl(null);
	}

	function handleChangeOnDatePicker(item) {
		setDateRange([item.selection]);
		dateRangeChangedCallback([item.selection]);
	}

	return (
		<>
			{ReactDOM.createPortal(
				<ThemeProvider theme={mainTheme}>
					<Popover
						id="compare-period"
						open={!!anchorEl}
						anchorEl={anchorEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
					>
						<Box>
							<CustomDateRangePicker
								months={2}
								ranges={dateRange}
								onChange={(item) => handleChangeOnDatePicker(item)}
							/>
						</Box>
					</Popover>
				</ThemeProvider>,
				document.querySelector('#root')
			)}
			<Button
				// className={clsx({"rounded-sm": props.flexPosStyling})}
				size="small"
				fullWidth
				variant="contained"
				onClick={(e) => setAnchorEl(e.currentTarget)}
				color="primary"
				style={{ minHeight: '37px' }}
			>
				{`${moment(dateRange[0].startDate).format('DD-MM-YYYY')} - ${moment(dateRange[0].endDate).format(
					'DD-MM-YYYY'
				)}`}
			</Button>
		</>
	);
}

export default PeriodSelectorForOverviewPage;
