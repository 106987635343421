import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const Root = styled('div')(({ theme }) => ({
	'& > .logo-icon': {
		transition: theme.transitions.create(['width', 'height'], {
			duration: theme.transitions.duration.shortest,
			easing: theme.transitions.easing.easeInOut,
		}),
	},
	'& > .badge, & > .logo-text': {
		transition: theme.transitions.create('opacity', {
			duration: theme.transitions.duration.shortest,
			easing: theme.transitions.easing.easeInOut,
		}),
	},
}));

function Logo() {
	return (
		<Root className="flex items-center">
			<img className="logo-icon" src="assets/images/logos/amerowhite.png" alt="logo" />
			<Typography className="logo-text text-16 leading-none mx-6 font-medium" color="inherit">
				Loppemodul
			</Typography>
		</Root>
	);
}

export default Logo;
