import React, { useEffect, useState } from 'react';
import {
	Typography,
	Dialog,
	Alert,
	Autocomplete,
	AppBar,
	Toolbar,
	Button,
	DialogContent,
	Snackbar,
	DialogActions,
	TextField,
	ThemeProvider,
	ButtonGroup,
} from '@mui/material';
import * as ReactDOM from 'react-dom';
import ConfirmationDialog from '@ameroservices-platform/shared/ui-components/ConfirmationDialog';
import firebaseService from '@ameroservices-platform/shared/services/firebase';
import { getFlexposLinkWithSyncActiveExists } from '@ameroservices-platform/shared/base-components/organisation/organisationSlice';
import { syncTypes } from '@ameroservices-platform/shared/enums/syncTypes';
import resyncDestinations from '@ameroservices-platform/shared/enums/resyncDestinations';
import resyncTypesEnum from '@ameroservices-platform/shared/enums/resyncTypes';
import { resyncItemsToSyncEngine } from '@ameroservices-platform/shared/base-components/sysAdmin/SysAdminFirestore';

const resyncTypesTranslated = {
	[resyncTypesEnum.CUSTOMERS]: 'Kunder',
	[resyncTypesEnum.PRODUCTS]: 'Produkter',
	[resyncTypesEnum.PRODUCT_GROUPS]: 'Produktgrupper',
	[resyncTypesEnum.CUSTOMER_TICKETS]: 'Billetter',
	[resyncTypesEnum.CUSTOMER_SUBSCRIPTIONS]: 'Medlemskaber',
	[resyncTypesEnum.ORDERS]: 'Ordrer',
	[resyncTypesEnum.BIG_QUERY]: 'Big query',
	[resyncTypesEnum.LOCAL_ELASTIC_SEARCH]: 'Elastic Search Lokalt',
};

const resyncDestinationsTranslated = {
	[resyncDestinations.FLEXPOS]: 'FlexPOS',
	[resyncDestinations.ELASTIC_SEARCH]: 'Elastic search',
};

function SyncAdminModal({ open, onClose, mainTheme, resyncTypes, overrideTranslated }) {
	const [loading, setLoading] = useState(false);
	const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
	const [alert, setAlert] = useState(null);
	const [chosenResyncOption, setChosenResyncOption] = useState(null);
	const [chosenResyncDestination, setChosenResyncDestination] = useState(null);
	const [currentResyncTypes, setCurrentResyncTypes] = useState(resyncTypes);
	const [snackbarMessage, setSnackbarMessage] = useState();

	useEffect(() => {
		if (process.env.NODE_ENV === 'development') {
			setCurrentResyncTypes((oldTypes) => {
				let newTypes = oldTypes;
				if (!oldTypes.includes(resyncTypesEnum.LOCAL_ELASTIC_SEARCH)) {
					newTypes = [...oldTypes, resyncTypesEnum.LOCAL_ELASTIC_SEARCH];
				}
				return newTypes;
			});
		}
	}, []);

	useEffect(() => {
		resetFormData();
		setAlert(null);
	}, []);

	const getResyncOptionLabel = (resyncOption) =>
		overrideTranslated && overrideTranslated[resyncOption]
			? overrideTranslated[resyncOption]
			: resyncTypesTranslated[resyncOption];

	const getDestinationOptionLabel = (destinationOption) => resyncDestinationsTranslated[destinationOption];

	const resetFormData = () => {
		setChosenResyncOption(null);
		setChosenResyncDestination(null);
	};

	const handleOnCloseDialog = async () => {
		onClose();
		resetFormData();
		setAlert(null);
	};

	const handleOnClickResyncButton = async () => {
		if (chosenResyncOption === resyncTypesEnum.LOCAL_ELASTIC_SEARCH) {
			await handleResync(true);
		} else if (chosenResyncDestination === resyncDestinations.FLEXPOS) {
			if (chosenResyncOption === resyncTypesEnum.CUSTOMERS) {
				const flexposLinkWithCustomerSyncActiveExists = await getFlexposLinkWithSyncActiveExists(
					syncTypes.CUSTOMER_SYNC
				);
				if (flexposLinkWithCustomerSyncActiveExists) {
					setShowConfirmationDialog(true);
				} else {
					setAlert({
						severity: 'warning',
						message:
							'Kunder kan ikke resynkroniseres til FlexPOS, da der ikke er aktiveret kunde synkronisering på et FlexPOS link',
					});
				}
			} else if (chosenResyncOption === resyncTypesEnum.PRODUCTS) {
				const flexposLinkWithProductSyncActiveExists = await getFlexposLinkWithSyncActiveExists(
					syncTypes.PRODUCT_SYNC
				);
				if (flexposLinkWithProductSyncActiveExists) {
					setShowConfirmationDialog(true);
				} else {
					setAlert({
						severity: 'warning',
						message:
							'Produkter kan ikke resynkroniseres til FlexPOS, da der ikke er aktiveret produkt synkronisering på et FlexPOS link',
					});
				}
			}
		} else {
			setShowConfirmationDialog(true);
		}
	};

	const handleResync = async (success) => {
		setShowConfirmationDialog(false);
		if (success) {
			setLoading(true);
			if (chosenResyncOption === 'localElasticSearch') {
				await firebaseService.callFunctionByName('sysAdminMigrationRunJob', {
					job: 'resyncToElasticSearch',
				});
			} else if (chosenResyncOption === 'bigQuery') {
				await firebaseService.callFunctionByName('bigQueryResyncBigQuery', {});
			} else {
				await resyncItemsToSyncEngine(chosenResyncOption, chosenResyncDestination);
			}

			setLoading(false);
			setSnackbarMessage(`Resynkronisering af ${getResyncOptionLabel(chosenResyncOption).toLowerCase()} startet`);
		}

		setShowConfirmationDialog(false);
		onClose();
	};

	const getDestinationOptions = () => {
		let destinationOptions = [];
		if (chosenResyncOption) {
			switch (chosenResyncOption) {
				case resyncTypesEnum.CUSTOMERS:
				case resyncTypesEnum.PRODUCTS:
					destinationOptions = [resyncDestinations.FLEXPOS, resyncDestinations.ELASTIC_SEARCH];
					break;
				case resyncTypesEnum.ORDERS:
				case resyncTypesEnum.CUSTOMER_SUBSCRIPTIONS:
				case resyncTypesEnum.CUSTOMER_TICKETS:
				case resyncTypesEnum.PRODUCT_GROUPS:
					destinationOptions = [resyncDestinations.ELASTIC_SEARCH];
					break;
				default:
					destinationOptions = [];
			}
		}
		return destinationOptions;
	};

	const getResyncButtonDisabled = () => {
		let resyncButtonDisabled = false;
		if (!chosenResyncOption) {
			resyncButtonDisabled = true;
		} else {
			if (
				!chosenResyncDestination &&
				chosenResyncOption !== resyncTypesEnum.BIG_QUERY &&
				chosenResyncOption !== resyncTypesEnum.LOCAL_ELASTIC_SEARCH
			) {
				resyncButtonDisabled = true;
			}
		}
		return resyncButtonDisabled;
	};

	return (
		<ThemeProvider theme={mainTheme}>
			<>
				<Dialog
					open={open}
					onClose={handleOnCloseDialog}
					aria-labelledby="form-dialog-title"
					classes={{
						paper: 'rounded-8 min-w-md',
					}}
				>
					<AppBar position="static">
						<Toolbar className="flex w-full">
							<Typography variant="subtitle1" color="inherit">
								Synkroniserings administration
							</Typography>
						</Toolbar>
					</AppBar>

					<DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}>
						<Autocomplete
							className="mt-8 mb-16 w-full"
							options={currentResyncTypes}
							value={chosenResyncOption}
							onChange={(event, newValue) => {
								setChosenResyncOption(newValue);
								setChosenResyncDestination(null);
								setAlert(null);
							}}
							getOptionLabel={getResyncOptionLabel}
							renderInput={(params) => (
								<TextField {...params} label="Resynkroniser..." variant="outlined" />
							)}
							openOnFocus
							disabled={loading}
						/>
						{chosenResyncOption &&
							chosenResyncOption !== resyncTypesEnum.BIG_QUERY &&
							chosenResyncOption !== resyncTypesEnum.LOCAL_ELASTIC_SEARCH && (
								<Autocomplete
									className="mt-8 mb-16 w-full"
									options={getDestinationOptions()}
									value={chosenResyncDestination}
									onChange={(event, newValue) => {
										setChosenResyncDestination(newValue);
									}}
									getOptionLabel={getDestinationOptionLabel}
									renderInput={(params) => (
										<TextField {...params} label="Vælg destination" variant="outlined" />
									)}
									openOnFocus
									disabled={loading}
								/>
							)}

						{alert && (
							<Alert severity={alert.severity} className="mb-10">
								{alert.message}
							</Alert>
						)}
						<ConfirmationDialog
							mainTheme={mainTheme}
							onClose={handleResync}
							keepMounted
							open={showConfirmationDialog}
							needToType={getResyncOptionLabel(chosenResyncOption)}
							content={`Er du sikker på, at du vil resynkronisere ${getResyncOptionLabel(
								chosenResyncOption
							)}?`}
							okButton="Ja"
							cancelButton="Nej"
							loading={loading}
							maxWidth="sm"
						/>
					</DialogContent>
					<DialogActions className="justify-end p-8">
						<div className="px-16">
							<ButtonGroup>
								<Button variant="outlined" onClick={handleOnCloseDialog}>
									Annuller
								</Button>
								<Button
									variant="contained"
									color="primary"
									onClick={handleOnClickResyncButton}
									disabled={getResyncButtonDisabled()}
								>
									Resynkroniser
								</Button>
							</ButtonGroup>
						</div>
					</DialogActions>
				</Dialog>
				{ReactDOM.createPortal(
					<Snackbar
						autoHideDuration={10000}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						open={!!snackbarMessage}
						onClose={() => setSnackbarMessage(null)}
						message={<span>{snackbarMessage}</span>}
					/>,
					document.body
				)}
			</>
		</ThemeProvider>
	);
}

export default SyncAdminModal;
