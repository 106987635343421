import React, { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const BoothTypeBoothType = lazy(() => import('./boothType/BoothType'));
const BoothTypesBoothTypes = lazy(() => import('./boothTypes/BoothTypes'));

const BoothTypesAppConfig = {
	settings: {
		layout: {},
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/apps/booth-types/:subscriptionTypeUid',
			element: <BoothTypeBoothType />,
		},
		{
			path: '/apps/booth-types',
			element: <BoothTypesBoothTypes />,
		},
	],
};

export default BoothTypesAppConfig;
