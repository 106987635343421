import firebaseService from '@ameroservices-platform/shared/services/firebase';

export const renewSubscriptions = async (subscriptions) => {
	const resp = await firebaseService.callFunctionByName('sysAdminRenewSubscriptions', { subscriptions });
	return resp.data;
};

export const getSubscriptionsToRenew = async (daysBefore = null, sendRenewalTime = null) => {
	const resp = await firebaseService.callFunctionByName('sysAdminGetSubscriptionsToRenew', {
		daysBefore,
		sendRenewalTime,
	});
	return resp.data;
};

export const getMigrationFunctions = async (cb) => {
	const resp = await firebaseService.callFunctionByName('sysAdminMigrationGetFunctions');
	cb(resp.data);
};

export const runMigrationFunctions = async (organisationUids) => {
	await firebaseService.callFunctionByName('sysAdminMigrationRunFunctions', {
		organisationUids,
	});
};

export const runAJob = async (job, organisations) => {
	const data = await firebaseService.callFunctionByName('sysAdminMigrationRunJob', { job, organisations });
	return data && data.data ? data.data : null;
};

export const runFlexposProductsJob = async (organisationId, flexposClientId, commitChanges) => {
	const data = await firebaseService.callFunctionByName('sysAdminMigrationRunFlexposProductsJob', {
		organisationId,
		flexposClientId,
		commitChanges,
	});
	return data && data.data ? data.data : null;
};

export const deleteOldFlexPOSCustomers = async () => {
	const data = await firebaseService.callFunctionByName('sysAdminDeleteOldFlexPOSCustomers', {});
	return data && data.data ? data.data : null;
};

export const resyncItemsToSyncEngine = async (type, destination) => {
	const data = await firebaseService.callFunctionByName('sysAdminResyncItemsToSyncEngine', {
		type,
		destination,
	});
	return data && data.data ? data.data : null;
};

export const resyncItemToSyncEngine = async (type, destination, itemUid) => {
	const data = await firebaseService.callFunctionByName('sysAdminResyncItemToSyncEngine', {
		type,
		destination,
		itemUid,
	});
	return data && data.data ? data.data : null;
};

export const runDelete = async (type, selected, deleteAll) => {
	await firebaseService.callFunctionByName('sysAdminRunDelete', {
		organisationUid: firebaseService.getOrganisationId(),
		type,
		selected,
		deleteAll,
	});
};

export const createImportJob = async (name, type, fileLocation, onlyAmeroAdmin, extraData) => {
	const resp = await firebaseService.callFunctionByName('organisationImportJobCreateImportJob', {
		name,
		type,
		onlyAmeroAdmin,
		...extraData,
		fileLocation,
	});
	return resp.data.id;
};
export const createExportJob = async (name, type, onlyAmeroAdmin, extraData) => {
	const resp = await firebaseService.callFunctionByName('organisationExportJobCreateExportJob', {
		name,
		type,
		onlyAmeroAdmin,
		extraData,
	});
	return resp.data.id;
};

export const updateTicketValidationUse = async (organisationUid, type) => {
	await firebaseService.callFunctionByName('sysAdminUpdateTicketValidationUse', { organisationUid, type });
};

export const exportJobsListener = (type, callback) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('exportJobs')
		.where('deleted', '==', false)
		.where('type', '==', type)
		.onSnapshot((query) => {
			const exportJobs = {};
			query.forEach((doc) => {
				exportJobs[doc.id] = { ...doc.data(), id: doc.id };
			});
			callback(exportJobs);
		});
};

export const importJobsListener = (callback) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('importJobs')
		.where('deleted', '==', false)
		.where('type', '==', 'reportValidation')
		.onSnapshot((query) => {
			const importJobs = [];
			query.forEach((doc) => {
				importJobs.push({ ...doc.data(), id: doc.id });
			});
			importJobs.sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate());
			callback(importJobs);
		});
};

export const collectionsListener = (collection, callback) => {
	const db = firebaseService.getRootDB();
	return db
		.collectionGroup(collection)
		.where('organisationUid', '==', firebaseService.getOrganisationId())
		.where('deleted', '==', false)
		.onSnapshot((querySnapshot) => {
			const tickets = [];
			querySnapshot.forEach((doc) => {
				const docData = doc.data();
				tickets.push({ ...docData, id: doc.id });
			});
			callback(tickets);
		});
};

export const ticketsListener = (callback) => {
	const db = firebaseService.getRootDB();
	return db
		.collectionGroup('customer_tickets')
		.where('organisationUid', '==', firebaseService.getOrganisationId())
		.where('deleted', '==', false)
		.onSnapshot((querySnapshot) => {
			const tickets = [];
			querySnapshot.forEach((doc) => {
				const docData = doc.data();
				tickets.push({ ...docData, id: doc.id });
			});
			callback(tickets);
		});
};

export const organisationsListener = (callback) => {
	const db = firebaseService.getRootDB();
	return db
		.collection('organisations')
		.where('deleted', '==', false)
		.onSnapshot((querySnapshot) => {
			const organisations = [];
			querySnapshot.forEach((doc) => {
				const docData = doc.data();
				organisations.push({ ...docData, id: doc.id });
			});
			callback(organisations);
		});
};

export const organisationListener = (uid, callback) => {
	const db = firebaseService.getRootDB();
	return db
		.collection('organisations')
		.doc(uid)
		.onSnapshot((q) => {
			callback({ ...q.data(), id: q.id });
		});
};
