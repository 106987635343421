import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableHead from '@mui/material/TableHead';
import FuseScrollbars from '@ameroservices-platform/loppe-backend/fuse/core/FuseScrollbars';
import FuseLoading from '@ameroservices-platform/loppe-backend/fuse/core/FuseLoading';
import { useDispatch, useSelector } from 'react-redux';
import {
	bankFileRecordsListener,
	selectBankFileRecords,
} from '@ameroservices-platform/loppe-backend/app/main/apps/bankPayouts/bankPayoutsSlice';
import BankFileRecordsTableRow from '@ameroservices-platform/loppe-backend/app/main/apps/bankPayouts/bankFile/tabs/bankFileRecords/BankFileRecordsTableRow';

const staticTableColumns = [
	{
		id: 'customerNumber',
		align: 'left',
		disablePadding: false,
		label: 'Kundenummer',
		sort: false,
	},
	{
		id: 'customerName',
		align: 'left',
		disablePadding: false,
		label: 'Kundenavn',
		sort: false,
	},
	{
		id: 'customerRegNumber',
		align: 'left',
		disablePadding: false,
		label: 'Kunde reg. nummer',
		sort: false,
	},
	{
		id: 'customerAccNumber',
		align: 'left',
		disablePadding: false,
		label: 'Kunde kontonummer',
		sort: false,
	},
	{
		id: 'payoutAmount',
		align: 'left',
		disablePadding: false,
		label: 'Udbetalingsbeløb',
		sort: false,
	},
];

function BankFileRecordsTable() {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(25);
	const [order, setOrder] = useState({
		direction: 'desc',
		id: 'createdDate',
	});

	const navigate = useNavigate();

	const routeParams = useParams();
	const dispatch = useDispatch();
	const bankFileRecords = useSelector(selectBankFileRecords);

	useEffect(() => {
		const bankFileRecordsListenerUnsubFunc = dispatch(bankFileRecordsListener(routeParams.bankFileUid));
		return () => {
			bankFileRecordsListenerUnsubFunc();
		};
	}, [dispatch, routeParams]);

	function handleRequestSort(event, property) {
		const id = property;
		let direction = 'desc';

		if (order.id === property && order.direction === 'desc') {
			direction = 'asc';
		}

		setOrder({
			direction,
			id,
		});
	}

	const createSortHandler = (property) => (event) => {
		handleRequestSort(event, property);
	};

	function handleChangePage(event, value) {
		setPage(value);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(event.target.value);
	}

	if (!bankFileRecords) {
		return <FuseLoading />;
	}

	return (
		<div className="w-full flex flex-col">
			<FuseScrollbars className="flex-grow overflow-x-auto">
				<Table aria-label="collapsible table">
					<TableHead className="bg-gray-100">
						<TableRow>
							{staticTableColumns.map((column) => {
								return (
									<TableCell
										key={column.id}
										align={column.align}
										sortDirection={order.id === column.id ? order.direction : false}
									>
										{column.sort ? (
											<Tooltip
												title="Sorter"
												placement={column.align === 'right' ? 'bottom-end' : 'bottom-start'}
												enterDelay={300}
											>
												<TableSortLabel
													active={order.id === column.id}
													direction={order.direction}
													onClick={createSortHandler(column.id)}
												>
													{column.label}
												</TableSortLabel>
											</Tooltip>
										) : (
											column.label
										)}
									</TableCell>
								);
							}, this)}
						</TableRow>
					</TableHead>
					<TableBody>
						{_.orderBy(
							bankFileRecords,
							[
								(o) => {
									return o.customerNumber;
								},
							],
							[order.direction]
						)
							.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
							.map((bankFileRecord, index) => (
								<BankFileRecordsTableRow
									key={index}
									bankFileRecord={bankFileRecord}
									onClick={() => {
										navigate(`/apps/bank-payouts/${routeParams.bankFileUid}/${bankFileRecord.id}`);
									}}
								/>
							))}
					</TableBody>
				</Table>
			</FuseScrollbars>

			<TablePagination
				className="overflow-hidden flex-shrink-0"
				component="div"
				count={bankFileRecords.length}
				rowsPerPage={rowsPerPage}
				page={page}
				backIconButtonProps={{
					'aria-label': 'Previous Page',
				}}
				nextIconButtonProps={{
					'aria-label': 'Next Page',
				}}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			/>
		</div>
	);
}

export default BankFileRecordsTable;
