import React, { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const LocationLocation = lazy(() => import('./location/Location'));
const LocationsLocations = lazy(() => import('./locations/Locations'));

const LocationsAppConfig = {
	settings: {
		layout: {},
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/apps/locations/:locationUid',
			element: <LocationLocation />,
		},
		{
			path: '/apps/locations',
			element: <LocationsLocations />,
		},
	],
};

export default LocationsAppConfig;
