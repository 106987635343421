import React from 'react';
import authRoles from '../../../auth/authRoles';
import BankFile from '@ameroservices-platform/loppe-backend/app/main/apps/bankPayouts/bankFile/BankFile';
import BankFileRecord from '@ameroservices-platform/loppe-backend/app/main/apps/bankPayouts/bankFile/bankFileRecord/BankFileRecord';

const BankPayouts = React.lazy(() => import('./BankPayouts'));

const BankPayoutsAppConfig = {
	settings: {
		layout: {},
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/apps/bank-payouts',
			element: <BankPayouts />,
		},
		{
			path: '/apps/bank-payouts/:bankFileUid',
			element: <BankFile />,
		},
		{
			path: '/apps/bank-payouts/:bankFileUid/:bankFileRecordUid',
			element: <BankFileRecord />,
		},
	],
};

export default BankPayoutsAppConfig;
