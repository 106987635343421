import { createSlice } from '@reduxjs/toolkit';
import firebaseService from '@ameroservices-platform/shared/services/firebase';

const initialState = {
	boothSummaries: null,
	boothSummariesMonths: {},
	boothTypes: null,
	booths: null
};

const overviewSlice = createSlice({
	name: 'overviewApp',
	initialState,
	reducers: {
		setBoothSummaries(state, action) {
			const { boothTypeUid, summaries, months } = action.payload;
			summaries.sort((a, b) => {
				const [aMonth, aYear] = a.month.split('-');
				const [bMonth, bYear] = b.month.split('-');
				return (
					Number.parseInt(`${aYear}${aMonth.padStart(2, '0')}`, 10) -
					Number.parseInt(`${bYear}${bMonth.padStart(2, '0')}`, 10)
				);
			});
			if (!state.boothSummaries) {
				state.boothSummaries = {};
			}
			state.boothSummaries[boothTypeUid] = summaries;
			state.boothSummariesMonths[boothTypeUid] = months;
		},
		setBoothTypes(state, action) {
			state.boothTypes = action.payload;
		},
		setBooths(state, action) {
			state.booths = action.payload;
		}
	}
});

export const { setBoothSummaries, setBoothTypes, setBooths } = overviewSlice.actions;

export default overviewSlice.reducer;

export const boothSummariesListener = (boothTypeUids, months) => dispatch => {
	return boothTypeUids.map(boothTypeUid =>
		firebaseService
			.getOrganisationRootDB()
			.collection('subscriptionTypes')
			.doc(boothTypeUid)
			.collection('boothSummaryByMonth')
			.where('month', 'in', months)
			.onSnapshot(query => {
				dispatch(
					setBoothSummaries({
						boothTypeUid,
						summaries: query.docs.map(doc => ({ ...doc.data(), id: doc.id })),
						months
					})
				);
			})
	);
};

export const boothTypesListener = () => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('subscriptionTypes')
		.where('deleted', '==', false)
		.onSnapshot(query => {
			dispatch(setBoothTypes(query.docs.map(doc => ({ ...doc.data(), id: doc.id }))));
		});
};

export const getBooths = () => dispatch => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('ticketTypes')
		.where('activeCampaigns', '==', true)
		.onSnapshot(query => {
			dispatch(setBooths(query.docs.map(doc => ({ ...doc.data(), id: doc.id }))));
		});
};

export const selectBoothTypes = state => state.overviewApp.boothTypes;
export const selectBoothSummaries = state => state.overviewApp.boothSummaries;
export const selectBoothSummariesMonths = state => state.overviewApp.boothSummariesMonths;
export const selectBooths = state => state.overviewApp.booths;
