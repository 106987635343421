import Typography from '@mui/material/Typography';
import moment from 'moment-timezone';
import PeriodSelectorForOverviewPage from '@ameroservices-platform/loppe-backend/app/ui-components/PeriodSelectorForOverviewPage';
import { useEffect, useMemo, useState } from 'react';
import withReducer from '@ameroservices-platform/loppe-backend/app/store/withReducer';
import reducer, {
	selectBoothSummaries
} from '@ameroservices-platform/loppe-backend/app/main/apps/overview/overviewSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
	boothSummariesListener,
	selectBoothTypes,
	boothTypesListener,
	selectBooths,
	getBooths
} from '@ameroservices-platform/loppe-backend/app/main/apps/overview/overviewSlice';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import mixpanel from 'mixpanel-browser';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Skeleton } from '@mui/material';

const nextSevenDays = {
	startDate: moment().startOf('day').toDate(),
	endDate: moment().startOf('day').add(6, 'days').toDate(),
	key: 'selection'
};
function OverviewPage() {
	const dispatch = useDispatch();
	const boothTypes = useSelector(selectBoothTypes);
	const boothsWithActiveCampaigns = useSelector(selectBooths);
	const [selectedDateRange, setSelectedDateRange] = useState(nextSevenDays);
	const flags = useFlags();
	const boothSummaries = useSelector(selectBoothSummaries);
	const maxNumberOfMonthsToBeLoaded = 13;

	const months = useMemo(() => {
		const startDate = moment(selectedDateRange.startDate);
		const endDate = moment(selectedDateRange.endDate);
		const betweenMonths = [];

		if (startDate <= endDate) {
			const date = startDate.startOf('month');

			while (date < endDate.endOf('month')) {
				betweenMonths.push(date.format('YYYY-MM'));
				date.add(1, 'month');
			}
		}
		return betweenMonths;
	}, [selectedDateRange]);

	const allBoothsFromThisMonth = useMemo(() => {
		if (!boothSummaries) {
			return null;
		}
		const listOfBooths = [];
		if (Object.keys(boothSummaries).length > 0) {
			Object.entries(boothSummaries).forEach(([boothTypeUid, summaryArray]) => {
				Object.values(summaryArray).forEach(summary => {
					if (summary.locations) {
						Object.values(summary.locations).forEach(location => {
							location.bookedDays.forEach(day => {
								const boothData = {};
								if (day?.bookedBy?.booth?.event?.end) {
									boothData.end = day.bookedBy.booth.event.end.toDate();
								}
								if (day?.bookedBy?.booth?.event?.start) {
									boothData.start = day.bookedBy.booth.event.start.toDate();
								}
								if (day.bookedBy && day.bookedBy.booth) {
									boothData.boothNumber = day.bookedBy.booth.productGroupNumber;
									boothData.customerUid = day.bookedBy.booth.customerUid;
									boothData.subscriptionUid = day.bookedBy.booth.subscriptionUid;
									boothData.name = day.bookedBy.booth.name;
								}
								if (day.bookedBy && day.bookedBy.customer) {
									boothData.customerName =
										day.bookedBy.customer.firstname + ' ' + day.bookedBy.customer.lastname;
								}

								if (!listOfBooths.some(booth => booth.boothNumber === boothData.boothNumber)) {
									listOfBooths.push(boothData);
								}
							});
						});
					}
				});
			});
		}
		return listOfBooths;
	}, [boothSummaries, selectedDateRange]);

	const boothsEndingInSelectedDateRange = useMemo(() => {
		if (!allBoothsFromThisMonth) {
			return null;
		}
		const booths = allBoothsFromThisMonth.filter(
			booth =>
				booth.end &&
				moment(booth.end).isBetween(
					moment(selectedDateRange.startDate),
					moment(selectedDateRange.endDate),
					'day',
					'[]'
				)
		);

		return booths.sort((a, b) => moment(a.end).valueOf() - moment(b.end).valueOf());
	}, [allBoothsFromThisMonth, selectedDateRange]);

	const boothsStartingInSelectedDateRange = useMemo(() => {
		if (!allBoothsFromThisMonth) {
			return null;
		}
		const booths = allBoothsFromThisMonth.filter(
			booth =>
				booth.start &&
				moment(booth.start).isBetween(
					moment(selectedDateRange.startDate),
					moment(selectedDateRange.endDate),
					'day',
					'[]'
				)
		);
		return booths.sort((a, b) => moment(a.start).valueOf() - moment(b.start).valueOf());
	}, [boothSummaries, selectedDateRange]);

	const boothCampaignsStartingInSelectedDateRange = useMemo(() => {
		if (!boothsWithActiveCampaigns) {
			return null;
		}
		const booths = boothsWithActiveCampaigns
			.map(booth => {
				return {
					...booth,
					activeCampaign: Object.values(booth.campaigns).find(campaign =>
						moment(campaign.start.toDate()).isBetween(
							moment(selectedDateRange.startDate),
							moment(selectedDateRange.endDate),
							'day',
							'[]'
						)
					)
				};
			})
			.filter(booth => !!booth.activeCampaign);
		return booths.sort((a, b) => a.activeCampaign.start.toDate() - b.activeCampaign.start.toDate());
	}, [boothsWithActiveCampaigns, selectedDateRange]);

	const boothCampaignsEndingInSelectedDateRange = useMemo(() => {
		if (!boothsWithActiveCampaigns) {
			return null;
		}
		const booths = boothsWithActiveCampaigns
			.map(booth => {
				return {
					...booth,
					activeCampaign: Object.values(booth.campaigns).find(campaign =>
						moment(campaign.end.toDate()).isBetween(
							moment(selectedDateRange.startDate),
							moment(selectedDateRange.endDate),
							'day',
							'[]'
						)
					)
				};
			})
			.filter(booth => !!booth.activeCampaign);
		return booths.sort((a, b) => a.activeCampaign.end.toDate() - b.activeCampaign.end.toDate());
	}, [boothsWithActiveCampaigns, selectedDateRange]);

	useEffect(() => {
		const unsubFunc = dispatch(boothTypesListener());
		return () => unsubFunc();
	}, [dispatch]);

	useEffect(() => {
		if (!boothTypes || months.length > maxNumberOfMonthsToBeLoaded) {
			return () => {};
		}
		const unsubFunc = dispatch(
			boothSummariesListener(
				boothTypes.map(boothType => boothType.id),
				months
			)
		);
		return () => unsubFunc.forEach(func => func());
	}, [dispatch, boothTypes, months]);

	useEffect(() => {
		dispatch(getBooths());
	}, [dispatch]);

	const statistics = useMemo(
		() => [
			{
				name: 'Stande starter',
				value: boothsStartingInSelectedDateRange?.length ?? null,
				className: 'text-green'
			},
			{ name: 'Stande udløber', value: boothsEndingInSelectedDateRange?.length ?? null, className: 'text-red' },
			{
				name: 'Standrabatter starter',
				value: boothCampaignsStartingInSelectedDateRange?.length ?? null,
				className: 'text-blue'
			},
			{
				name: 'Standrabatter udløber',
				value: boothCampaignsEndingInSelectedDateRange?.length ?? null,
				className: 'text-orange'
			}
		],
		[
			boothsEndingInSelectedDateRange,
			boothsStartingInSelectedDateRange,
			boothCampaignsStartingInSelectedDateRange,
			boothCampaignsEndingInSelectedDateRange
		]
	);

	function handleDateRangeChange(dateRange) {
		setSelectedDateRange(dateRange[0]);
		mixpanel.track('Date change', {
			category: 'Booth overview'
		});
	}

	const daysBetween = useMemo(
		() =>
			Math.ceil(
				moment(selectedDateRange.endDate)
					.endOf('day')
					.diff(moment(selectedDateRange.startDate).startOf('day'), 'day', true)
			),
		[selectedDateRange]
	);

	if (!flags.overviewHomePageOnLoppe) {
		return (
			<div className="flex flex-col">
				<Typography className="h3 sm:h2 font-medium">Denne side er under opbygning</Typography>
			</div>
		);
	}
	return (
		<div className="mx-20 my-10">
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<div className="flex items-center justify-between p-10">
						<Typography className="text-18 font-bold leading-none tracking-tight">
							Overblik over de næste {daysBetween} dage
						</Typography>
						<div>
							<PeriodSelectorForOverviewPage
								dateRangeChangedCallback={handleDateRangeChange}
								initialState={nextSevenDays}
							/>
						</div>
					</div>
				</Grid>
				{statistics.map(stat => (
					<Grid item xs={3} key={stat.name}>
						<Paper className="w-full rounded-20 shadow flex flex-col justify-between">
							<div className="text-center py-12">
								<Typography
									className={clsx(
										'text-72 font-semibold leading-none tracking-tighter',
										stat.className
									)}
								>
									{stat.value === null && (
										<div className={'flex justify-center'}>
											<Skeleton
												variant={'text'}
												style={{ width: '9rem', fontSize: '11.2rem', margin: '-2rem 0' }}
											/>
										</div>
									)}
									{stat.value}
								</Typography>
								<Typography className="text-18 font-normal">{stat.name}</Typography>
							</div>
						</Paper>
					</Grid>
				))}
				<Grid item xs={6}>
					<Grid container rowSpacing={3}>
						<Grid item xs={12}>
							<Paper className="w-full rounded-20 shadow overflow-hidden">
								<div className="flex items-center justify-between p-20 h-64">
									<Typography className="text-16 font-medium">
										Stande der starter de næste {daysBetween} dage
									</Typography>
								</div>
								<div className="table-responsive">
									{!boothsStartingInSelectedDateRange && (
										<Skeleton variant={'rectangular'} style={{ height: '5rem' }} />
									)}
									{boothsStartingInSelectedDateRange && (
										<Table className="w-full min-w-full">
											<TableHead>
												<TableRow>
													<TableCell>
														<Typography
															color="textSecondary"
															className="font-semibold whitespace-nowrap"
														>
															Lokation
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															color="textSecondary"
															className="font-semibold whitespace-nowrap"
														>
															Starter
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															color="textSecondary"
															className="font-semibold whitespace-nowrap"
														>
															Kunde
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															color="textSecondary"
															className="font-semibold whitespace-nowrap"
														>
															Standnummer
														</Typography>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{boothsStartingInSelectedDateRange.length === 0 && (
													<TableRow>
														<TableCell colSpan={4}>
															<Typography className={'text-center'}>
																Der er ingen stande at vise
															</Typography>
														</TableCell>
													</TableRow>
												)}
												{boothsStartingInSelectedDateRange.map(booth => (
													<TableRow key={booth.id} className="h-64">
														<TableCell scope="row">
															<Typography>{booth.name}</Typography>
														</TableCell>
														<TableCell scope="row">
															<Typography>
																{moment(booth.start)
																	.locale('da')
																	.format('dddd DD-MM-YYYY')}
															</Typography>
														</TableCell>
														<TableCell scope="row">
															<Typography
																component={Link}
																to={`/apps/booth-owners/${booth.customerUid}`}
															>
																{booth.customerName}
															</Typography>
														</TableCell>
														<TableCell scope="row">
															<Typography
																component={Link}
																to={`/apps/booth-subscriptions/details/${booth.customerUid}/${booth.subscriptionUid}`}
															>
																{booth.boothNumber}
															</Typography>
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									)}
								</div>
							</Paper>
						</Grid>
						<Grid item xs={12}>
							<Paper className="w-full rounded-20 shadow overflow-hidden">
								<div className="flex items-center justify-between p-20 h-64">
									<Typography className="text-16 font-medium">
										Stande der udløber de næste {daysBetween} dage
									</Typography>
								</div>
								<div className="table-responsive">
									{!boothsEndingInSelectedDateRange && (
										<Skeleton variant={'rectangular'} style={{ height: '5rem' }} />
									)}
									{boothsEndingInSelectedDateRange && (
										<Table className="w-full min-w-full">
											<TableHead>
												<TableRow>
													<TableCell>
														<Typography
															color="textSecondary"
															className="font-semibold whitespace-nowrap"
														>
															Lokation
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															color="textSecondary"
															className="font-semibold whitespace-nowrap"
														>
															Udløber
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															color="textSecondary"
															className="font-semibold whitespace-nowrap"
														>
															Kunde
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															color="textSecondary"
															className="font-semibold whitespace-nowrap"
														>
															Standnummer
														</Typography>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{boothsEndingInSelectedDateRange.length === 0 && (
													<TableRow>
														<TableCell colSpan={4}>
															<Typography className={'text-center'}>
																Der er ingen stande at vise
															</Typography>
														</TableCell>
													</TableRow>
												)}
												{boothsEndingInSelectedDateRange.map(booth => (
													<TableRow key={booth.id} className="h-64">
														<TableCell scope="row">
															<Typography>{booth.name}</Typography>
														</TableCell>
														<TableCell scope="row">
															<Typography>
																{moment(booth.end)
																	.locale('da')
																	.format('dddd DD-MM-YYYY')}
															</Typography>
														</TableCell>
														<TableCell scope="row">
															<Typography
																component={Link}
																to={`/apps/booth-owners/${booth.customerUid}`}
															>
																{booth.customerName}
															</Typography>
														</TableCell>
														<TableCell scope="row">
															<Typography
																component={Link}
																to={`/apps/booth-subscriptions/details/${booth.customerUid}/${booth.subscriptionUid}`}
															>
																{booth.boothNumber}
															</Typography>
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									)}
								</div>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={6}>
					<Grid container rowSpacing={3}>
						<Grid item xs={12}>
							<Paper className="w-full rounded-20 shadow overflow-hidden">
								<div className="flex items-center justify-between p-20 h-64">
									<Typography className="text-16 font-medium">
										Standrabatter der starter de næste {daysBetween} dage
									</Typography>
								</div>
								<div className="table-responsive">
									{!boothCampaignsStartingInSelectedDateRange && (
										<Skeleton variant={'rectangular'} style={{ height: '5rem' }} />
									)}
									{boothCampaignsStartingInSelectedDateRange && (
										<Table className="w-full min-w-full">
											<TableHead>
												<TableRow>
													<TableCell>
														<Typography
															color="textSecondary"
															className="font-semibold whitespace-nowrap"
														>
															Lokation
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															color="textSecondary"
															className="font-semibold whitespace-nowrap"
														>
															Starter
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															color="textSecondary"
															className="font-semibold whitespace-nowrap"
														>
															Procent
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															color="textSecondary"
															className="font-semibold whitespace-nowrap"
														>
															Standnummer
														</Typography>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{boothCampaignsStartingInSelectedDateRange.length === 0 && (
													<TableRow>
														<TableCell colSpan={4}>
															<Typography className={'text-center'}>
																Der er ingen standrabatter at vise
															</Typography>
														</TableCell>
													</TableRow>
												)}
												{boothCampaignsStartingInSelectedDateRange.map(booth => (
													<TableRow key={booth.id} className="h-64">
														<TableCell scope="row">
															<Typography>{booth.name}</Typography>
														</TableCell>
														<TableCell scope="row">
															<Typography>
																{moment(booth.activeCampaign.start.toDate())
																	.locale('da')
																	.format('dddd DD-MM-YYYY')}
															</Typography>
														</TableCell>
														<TableCell scope="row">
															<Typography>{booth.activeCampaign.percentage}%</Typography>
														</TableCell>
														<TableCell scope="row">
															<Typography
																component={Link}
																to={`/apps/booth-subscriptions/details/${booth.customerUid}/${booth.subscriptionUid}`}
															>
																{booth.productGroupNumber}
															</Typography>
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									)}
								</div>
							</Paper>
						</Grid>
						<Grid item xs={12}>
							<Paper className="w-full rounded-20 shadow overflow-hidden">
								<div className="flex items-center justify-between p-20 h-64">
									<Typography className="text-16 font-medium">
										Standrabatter der udløber de næste {daysBetween} dage
									</Typography>
								</div>
								<div className="table-responsive">
									{!boothCampaignsEndingInSelectedDateRange && (
										<Skeleton variant={'rectangular'} style={{ height: '5rem' }} />
									)}
									{boothCampaignsEndingInSelectedDateRange && (
										<Table className="w-full min-w-full">
											<TableHead>
												<TableRow>
													<TableCell>
														<Typography
															color="textSecondary"
															className="font-semibold whitespace-nowrap"
														>
															Lokation
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															color="textSecondary"
															className="font-semibold whitespace-nowrap"
														>
															Udløber
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															color="textSecondary"
															className="font-semibold whitespace-nowrap"
														>
															Procent
														</Typography>
													</TableCell>
													<TableCell>
														<Typography
															color="textSecondary"
															className="font-semibold whitespace-nowrap"
														>
															Standnummer
														</Typography>
													</TableCell>
												</TableRow>
											</TableHead>
											<TableBody>
												{boothCampaignsEndingInSelectedDateRange.length === 0 && (
													<TableRow>
														<TableCell colSpan={4}>
															<Typography className={'text-center'}>
																Der er ingen standrabatter at vise
															</Typography>
														</TableCell>
													</TableRow>
												)}
												{boothCampaignsEndingInSelectedDateRange.map(booth => (
													<TableRow key={booth.id} className="h-64">
														<TableCell scope="row">
															<Typography>{booth.name}</Typography>
														</TableCell>
														<TableCell scope="row">
															<Typography>
																{moment(booth.activeCampaign.end.toDate())
																	.locale('da')
																	.format('dddd DD-MM-YYYY')}
															</Typography>
														</TableCell>
														<TableCell scope="row">
															<Typography>{booth.activeCampaign.percentage}%</Typography>
														</TableCell>
														<TableCell scope="row">
															<Typography
																component={Link}
																to={`/apps/booth-subscriptions/details/${booth.customerUid}/${booth.subscriptionUid}`}
															>
																{booth.productGroupNumber}
															</Typography>
														</TableCell>
													</TableRow>
												))}
											</TableBody>
										</Table>
									)}
								</div>
							</Paper>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</div>
	);
}

export default withReducer('overviewApp', reducer)(OverviewPage);
