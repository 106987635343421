import React from 'react';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { moneyFormatter } from '@ameroservices-platform/shared/utility/numbers/digits';

function BankFileRecordsTableRow({ bankFileRecord, onClick }) {
	return (
		<TableRow
			role="checkbox"
			tabIndex={-1}
			hover
			onClick={(event) => {
				if (onClick && onClick instanceof Function) {
					onClick(bankFileRecord, event);
				}
			}}
			className={'h-72 cursor-pointer'}
		>
			<TableCell className="truncate" component="th" scope="row">
				{bankFileRecord.customerNumber}
			</TableCell>
			<TableCell component="td" scope="row">
				{`${bankFileRecord.customerFirstName} ${bankFileRecord.customerLastName}`}
			</TableCell>
			<TableCell component="td" scope="row">
				{bankFileRecord.customerRegNumber}
			</TableCell>
			<TableCell component="td" scope="row">
				{bankFileRecord.customerAccNumber}
			</TableCell>
			<TableCell component="td" scope="row">
				{moneyFormatter.format(bankFileRecord.payoutAmount / 100.0)}
			</TableCell>
		</TableRow>
	);
}

export default BankFileRecordsTableRow;
