import ChatPanel from '@ameroservices-platform/loppe-backend/app/fuse-layouts/shared-components/chatPanel/ChatPanel';
import QuickPanel from '@ameroservices-platform/loppe-backend/app/fuse-layouts/shared-components/quickPanel/QuickPanel';
import { memo } from 'react';

function RightSideLayout3() {
	return (
		<>
			<ChatPanel />

			<QuickPanel />
		</>
	);
}

export default memo(RightSideLayout3);
