import React from 'react';
import authRoles from '../../../auth/authRoles';

const BoothOwnersBoothOwners = React.lazy(() => import('./boothOwners/BoothOwners'));
const BoothOwnerBoothOwner = React.lazy(() => import('./boothOwner/BoothOwner'));

const BoothOwnersAppConfig = {
	settings: {
		layout: {},
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/apps/booth-owners/:customerUid',
			element: <BoothOwnerBoothOwner />,
		},
		{
			path: '/apps/booth-owners',
			element: <BoothOwnersBoothOwners />,
		},
	],
};

export default BoothOwnersAppConfig;
