const syncTypes = Object.freeze({
	PRODUCT_SYNC: 'productSync',
	PRODUCT_SYNC_FROM_FLEXPOS: 'productSyncFromFlexPOS',
	CUSTOMER_SYNC: 'customerSync',
	ORDER_SYNC: 'orderSync',
	STOCK_SYNC: 'stockSync',
});

export {syncTypes}

export default syncTypes;
