import { combineReducers } from '@reduxjs/toolkit';
import auth from '@ameroservices-platform/loppe-backend/app/auth/store';
import fuse from './fuse';
import i18n from './i18nSlice';
import shared from './shared';

const createReducer = (asyncReducers) => (state, action) => {
	const combinedReducer = combineReducers({
		auth,
		fuse,
		i18n,
		shared,
		...asyncReducers,
	});

	/*
	Reset the redux store when user logged out
	 */
	if (action.type === 'auth/user/userLoggedOut') {
		// state = undefined;
	}

	return combinedReducer(state, action);
};

export default createReducer;
