import React from 'react';

import { Icon } from '@mui/material';
import Typography from '@mui/material/Typography';
import BankFileRecordSettlementOrderLinesTable from '@ameroservices-platform/loppe-backend/app/main/apps/bankPayouts/bankFile/bankFileRecord/tabs/bankFileRecordSettlementOrderLines/BankFileRecordSettlementOrderLinesTable';

function BankFileRecordSettlementOrderLines() {
	return (
		<div className={'p-16 sm:p-24 w-full'}>
			<div className="flex justify-between mb-8">
				<div className="flex items-center">
					<Icon color="action">text_snippet</Icon>
					<Typography className="h2 mx-16" color="textSecondary">
						Bank udbetalingslinjer
					</Typography>
				</div>
			</div>
			<BankFileRecordSettlementOrderLinesTable />
		</div>
	);
}

export default BankFileRecordSettlementOrderLines;
