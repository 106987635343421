const settlementAccountTypes = {
	CUSTOMER: 'customer',
	STORE: 'store',
	PAYOUT_BANK: 'payoutBank',
	PAYOUT_PROCESSED_BANK: 'payoutProcessedBank',
	PAYOUT_PROCESSED_POS: 'payoutProcessedPos',
};

export const settlementAccountTypesTranslate = {
	[settlementAccountTypes.CUSTOMER]: 'Standholder',
	[settlementAccountTypes.STORE]: 'Butik',
	[settlementAccountTypes.PAYOUT_BANK]: 'Udbetaling bank',
	[settlementAccountTypes.PAYOUT_PROCESSED_BANK]: 'Udbetaling processeret bank',
	[settlementAccountTypes.PAYOUT_PROCESSED_POS]: 'Udbetaling processeret POS',
};

export default settlementAccountTypes;
