import React, { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const Products = lazy(() => import('./Products'));

const ProductsAppConfig = {
	settings: {
		layout: {},
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/apps/products',
			element: <Products />,
		},
	],
};

export default ProductsAppConfig;
