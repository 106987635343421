import error404PageConfig from '@ameroservices-platform/loppe-backend/app/main/apps/error/404/error404PageConfig';
import error500PageConfig from '@ameroservices-platform/loppe-backend/app/main/apps/error/500/error500PageConfig';
import BoothOwnersAppConfig from './boothOwners/BoothOwnersAppConfig';
import OrganisationsAppConfig from './organisations/OrganisationsAppConfig';
import OrdersAppConfig from './orders/OrdersAppConfig';
import SalesAppConfig from './sales/SalesAppConfig';
import SysAdminAppConfig from './sysAdmin/SysAdminAppConfig';
import BoothTypesAppConfig from './boothTypes/BoothTypesAppConfig';
import BoothsAppConfig from './booths/BoothsAppConfig';
import BoothSubscriptionsAppConfig from './boothSubscriptions/BoothSubscriptionsAppConfig';
import TaxRatesAppConfig from './taxRates/TaxRatesAppConfig';
import LocationsAppConfig from './locations/LocationsAppConfig';
import IncrementalNumbersAppConfig from './incrementalNumbers/IncrementalNumbersAppConfig';
import MailTemplatesAppConfig from './mailTemplates/MailTemplatesAppConfig';
import DashboardPageConfig from './dashboard/DashboardPageConfig';
import ProductsAppConfig from './products/ProductsAppConfig';
import BankPayoutsAppConfig from '@ameroservices-platform/loppe-backend/app/main/apps/bankPayouts/BankPayoutsAppConfig';
import ImportJobsAppConfig from './importJobs/ImportJobsAppConfig';
import ExportJobsAppConfig from './exportJobs/ExportJobsAppConfig';
import overviewPageConfig from '@ameroservices-platform/loppe-backend/app/main/apps/overview/OverviewPageConfig';
import CommunicationTemplatesAppConfig from '@ameroservices-platform/loppe-backend/app/main/apps/communication-templates/CommunicationTemplatesAppConfig';

const appsConfigs = [
	BankPayoutsAppConfig,
	BoothOwnersAppConfig,
	OrganisationsAppConfig,
	OrdersAppConfig,
	SalesAppConfig,
	SysAdminAppConfig,
	BoothTypesAppConfig,
	BoothsAppConfig,
	BoothSubscriptionsAppConfig,
	TaxRatesAppConfig,
	LocationsAppConfig,
	IncrementalNumbersAppConfig,
	MailTemplatesAppConfig,
	CommunicationTemplatesAppConfig,
	DashboardPageConfig,
	ProductsAppConfig,
	ImportJobsAppConfig,
	ExportJobsAppConfig,
	error404PageConfig,
	error500PageConfig,
	overviewPageConfig
];

export default appsConfigs;
