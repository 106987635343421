import React from 'react';
import { Icon } from '@mui/material';
import Typography from '@mui/material/Typography';
import BankFileRecordsTable from '@ameroservices-platform/loppe-backend/app/main/apps/bankPayouts/bankFile/tabs/bankFileRecords/BankFileRecordsTable';

function BankFileRecords() {
	return (
		<div className={'p-16 sm:p-24 w-full'}>
			<div className="flex justify-between mb-8">
				<div className="flex items-center">
					<Icon color="action">text_snippet</Icon>
					<Typography className="h2 mx-16" color="textSecondary">
						Bankfil posteringer
					</Typography>
				</div>
			</div>
			<BankFileRecordsTable />
		</div>
	);
}

export default BankFileRecords;
