import React, { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const IncrementalNumbersIncrementalNumbers = lazy(() => import('./incrementalNumbers/IncrementalNumbers'));

const IncrementalNumbersAppConfig = {
	settings: {
		layout: {},
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/apps/incremental-numbers',
			element: <IncrementalNumbersIncrementalNumbers />,
		},
	],
};

export default IncrementalNumbersAppConfig;
