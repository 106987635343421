import React from 'react';
import { Icon, Input, Paper, Typography, Link as MUILink } from '@mui/material';
import { motion } from 'framer-motion';
import { ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function PageHeader({
	search,
	icon,
	subtitle,
	title,
	goBackLink,
	goBackText,
	goBack,
	mainTheme,
	searchText,
	setSearchText,
	createComponent: CreateComponent,
}) {
	if (!mainTheme && search) {
		console.error('mainTheme is a required prop');
		throw new Error('mainTheme is a required prop');
	}
	const navigate = useNavigate();

	function pageTitle() {
		return (
			(title || subtitle) && (
				<div className="flex flex-col min-w-0 items-center mx-16 sm:mc-16 sm:items-start">
					<motion.div
						initial={{ x: -20, opacity: 0 }}
						animate={{
							x: 0,
							opacity: 1,
							transition: { delay: 0.3 },
						}}
					>
						<Typography className="text-16 sm:text-20 truncate font-semibold">{title}</Typography>
						{subtitle && (
							<Typography variant="caption" className="font-medium">
								{subtitle}
							</Typography>
						)}
					</motion.div>
				</div>
			)
		);
	}

	if (!search) {
		return (
			<div className="flex flex-1 w-full items-center justify-between">
				<div className="flex flex-1 flex-col items-center sm:items-start">
					{(goBackLink || goBackText || goBack) && (
						<motion.div
							initial={{ x: 20, opacity: 0 }}
							animate={{
								x: 0,
								opacity: 1,
								transition: { delay: 0.3 },
							}}
						>
							<Typography
								className="flex items-center sm:mb-12"
								component={goBackLink ? Link : MUILink}
								role="button"
								to={goBackLink || undefined}
								onClick={() => {
									if (!goBackLink) {
										navigate(-1);
									}
								}}
								color="inherit"
							>
								<Icon className="text-20">arrow_back</Icon>
								<span className="mx-4 font-medium">{goBackText || 'Gå Tilbage'}</span>
							</Typography>
						</motion.div>
					)}

					<div className={'flex items-center max-w-full'}>
						{icon && (
							<Icon
								component={motion.span}
								initial={{ scale: 0 }}
								animate={{
									scale: 1,
									transition: { delay: 0.3 },
								}}
								className="text-24 md:text-32 hidden sm:flex"
							>
								{icon}
							</Icon>
						)}

						{pageTitle()}
					</div>
				</div>
				{CreateComponent && <CreateComponent mainTheme={mainTheme} />}
			</div>
		);
	}

	return (
		<div className="flex flex-1 w-full items-center justify-between">
			<div className="flex items-center">
				<Icon
					component={motion.span}
					initial={{ scale: 0 }}
					animate={{ scale: 1, transition: { delay: 0.2 } }}
					className="text-24 md:text-32"
				>
					{icon}
				</Icon>
				<Typography
					component={motion.span}
					initial={{ x: -20 }}
					animate={{ x: 0, transition: { delay: 0.2 } }}
					delay={300}
					className="text-16 md:text-24 mx-12 font-semibold"
				>
					{title}
				</Typography>
			</div>

			<div className="flex flex-1 items-center justify-center px-12">
				<ThemeProvider theme={mainTheme}>
					<Paper
						component={motion.div}
						initial={{ y: -20, opacity: 0 }}
						animate={{
							y: 0,
							opacity: 1,
							transition: { delay: 0.2 },
						}}
						className="flex items-center w-full max-w-512 px-8 py-4 rounded-16 shadow"
					>
						<Icon color="action">search</Icon>

						<Input
							placeholder="Søg"
							className="flex flex-1 mx-8"
							disableUnderline
							fullWidth
							value={searchText}
							inputProps={{
								'aria-label': 'Søg',
							}}
							onChange={(ev) => {
								const val = ev.currentTarget.value;
								setSearchText(val);
							}}
						/>
					</Paper>
				</ThemeProvider>
			</div>
			{CreateComponent && <CreateComponent />}
		</div>
	);
}

export default PageHeader;
