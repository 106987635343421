import React from 'react';
import authRoles from '../../../auth/authRoles';

const Sales = React.lazy(() => import('./Sales'));

const SalesAppConfig = {
	settings: {
		layout: {},
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/apps/sales',
			element: <Sales />,
		},
	],
};

export default SalesAppConfig;
