import { authRoles } from '@ameroservices-platform/loppe-backend/app/auth';
import store from '@ameroservices-platform/loppe-backend/app/store';
import { logoutUser } from '@ameroservices-platform/loppe-backend/app/auth/store/userSlice';

const LogoutConfig = {
	auth: authRoles.user,
	routes: [
		{
			path: 'logout',
			element: () => {
				store.dispatch(logoutUser());
				return 'Logging out..';
			},
		},
	],
};

export default LogoutConfig;
