import FusePageCarded from '@ameroservices-platform/loppe-backend/fuse/core/FusePageCarded';
import { styled } from '@mui/material/styles';
import React from 'react';

const Root = styled(FusePageCarded)(({ theme }) => ({
	'& .FusePageCarded-header': {
		minHeight: 72,
		height: 72,
		alignItems: 'center',
		[theme.breakpoints.up('sm')]: {
			minHeight: 136,
			height: 136,
		},
	},
	'& .FusePageCarded-content': {
		display: 'flex',
	},
	'& .FusePageCarded-contentCard': {
		overflow: 'hidden',
	},
}));

const RootWithAuditlog = styled(FusePageCarded)(({ theme }) => ({
	'& .FusePageCarded-sidebarContent': {
		background: 'transparent',
	},
	// '& .FusePageCarded-sidebarWrapper':  {
	//     overflow: 'visible',
	//     [theme.breakpoints.up('lg')]: {
	//         flex: "1 1 33%",
	//         minWidth: "43.2rem",
	//     },
	// },
	'& .FusePageCarded-sidebarWrapper': {
		width: '30%',
	},
	'& .FusePageCarded-contentWrapper': {
		paddingRight: '0.5rem',
	},
	'& .FusePageCarded-rightSidebar': {
		width: '100%',
	},
	'& .FusePageCarded-header': {
		minHeight: 72,
		height: 72,
		alignItems: 'center',
		[theme.breakpoints.up('sm')]: {
			minHeight: 136,
			height: 136,
		},
	},
	'& .FusePageCarded-sidebarHeader': {
		minHeight: 72,
		height: 72,
		alignItems: 'center',
		[theme.breakpoints.up('sm')]: {
			minHeight: 136,
			height: 136,
		},
	},
}));

const WrappedFusePageCarded = React.forwardRef((props, ref) => {
	if (props.auditLog || props.auditlog) {
		return <RootWithAuditlog ref={ref} {...props} />;
	}
	return <Root {...props} />;
});

export default WrappedFusePageCarded;
