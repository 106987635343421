import { createSlice } from '@reduxjs/toolkit';
import firebaseService from '@ameroservices-platform/shared/services/firebase';

const organisationSlice = createSlice({
	name: 'organisationApp',
	initialState: {
		organisation: null,
		content: null,
		users: null,
		flexposLinks: null,
		customers: null,
		locations: null
	},
	reducers: {
		setOrganisation: (state, action) => {
			state.organisation = action.payload;
		},
		setContent: (state, action) => {
			state.content = action.payload;
		},
		setOrganisationUsers: (state, action) => {
			state.users = action.payload;
		},
		setFlexposLinks: (state, action) => {
			state.flexposLinks = action.payload;
		},
		setCustomers: (state, action) => {
			state.customers = action.payload;
		},
		setLocations: (state, action) => {
			state.locations = action.payload;
		}
	}
});

export const { setOrganisation, setContent, setOrganisationUsers, setFlexposLinks, setCustomers, setLocations } =
	organisationSlice.actions;

export const organisationListener = organisationUid => dispatch => {
	const db = firebaseService.getRootDB();
	return db
		.collection('organisations')
		.doc(organisationUid)
		.onSnapshot(doc => {
			dispatch(setOrganisation({ ...doc.data(), id: doc.id }));
		});
};

export const contentListener = organisationUid => dispatch => {
	const db = firebaseService.getRootDB();
	return db
		.collection('content')
		.doc(organisationUid)
		.onSnapshot(doc => {
			dispatch(setContent({ ...doc.data(), id: doc.id }));
		});
};

export const organisationUsersListener = organisationId => dispatch => {
	const db = firebaseService.getRootDB();
	return db
		.collection('organisations')
		.doc(organisationId)
		.collection('organisation_users')
		.where('deleted', '==', false)
		.onSnapshot(querySnapshot => {
			const users = [];
			querySnapshot.forEach(doc => {
				const docData = doc.data();
				users.push({ ...docData, id: doc.id });
			});
			dispatch(setOrganisationUsers(users));
		});
};

export const flexposLinksListener = organisationId => dispatch => {
	const db = firebaseService.getRootDB();
	return db
		.collection('organisations')
		.doc(organisationId)
		.collection('flexposLinks')
		.where('deleted', '==', false)
		.onSnapshot(querySnapshot => {
			const flexposLinks = [];
			querySnapshot.forEach(doc => {
				const docData = doc.data();
				flexposLinks.push({ ...docData, id: doc.id });
			});
			dispatch(setFlexposLinks(flexposLinks));
		});
};

export const customersListener = organisationId => dispatch => {
	const db = firebaseService.getRootDB();
	return db
		.collection('organisations')
		.doc(organisationId)
		.collection('customers')
		.where('deleted', '==', false)
		.where('isAnonymous', '==', false)
		.where('isAssociated', '==', false)
		.onSnapshot(querySnapshot => {
			const customers = [];
			querySnapshot.forEach(doc => {
				const docData = doc.data();
				customers.push({ ...docData, id: doc.id });
			});
			dispatch(setCustomers(customers));
		});
};

export const locationsListener = organisationId => dispatch => {
	const db = firebaseService.getRootDB();
	return db
		.collection('organisations')
		.doc(organisationId)
		.collection('locations')
		.where('deleted', '==', false)
		.onSnapshot(querySnapshot => {
			const locations = [];
			querySnapshot.forEach(doc => {
				const docData = doc.data();
				locations.push({ ...docData, id: doc.id });
			});
			dispatch(setLocations(locations));
		});
};

export const flexposLinksListenerCallback = (organisationId, callback) => {
	const db = firebaseService.getRootDB();
	return db
		.collection('organisations')
		.doc(organisationId)
		.collection('flexposLinks')
		.where('deleted', '==', false)
		.onSnapshot(querySnapshot => {
			const flexposLinks = [];
			querySnapshot.forEach(doc => {
				const docData = doc.data();
				flexposLinks.push({ ...docData, id: doc.id });
			});
			callback(flexposLinks);
		});
};

export const customersByFlexposLinksListenerCallback = (organisationId, flexposLinks, callback) => {
	if (flexposLinks.length > 0) {
		const db = firebaseService.getRootDB();

		return flexposLinks
			.filter(flexposLink => flexposLink.defaultCustomerId)
			.map(flexposLink => {
				return db
					.collection('organisations')
					.doc(organisationId)
					.collection('customers')
					.doc(flexposLink.defaultCustomerId)
					.onSnapshot(querySnapshot => {
						callback({ ...querySnapshot.data(), id: querySnapshot.id });
					});
			});
	}
	return [];
};

export const customersListenerCallback = (organisationId, callback) => {
	const db = firebaseService.getRootDB();
	return db
		.collection('organisations')
		.doc(organisationId)
		.collection('customers')
		.where('deleted', '==', false)
		.where('isAnonymous', '==', false)
		.where('isAssociated', '==', false)
		.onSnapshot(querySnapshot => {
			const customers = [];
			querySnapshot.forEach(doc => {
				const docData = doc.data();
				customers.push({ ...docData, id: doc.id });
			});
			callback(customers);
		});
};

export const locationsListenerCallback = (organisationId, callback) => {
	const db = firebaseService.getRootDB();
	return db
		.collection('organisations')
		.doc(organisationId)
		.collection('locations')
		.where('deleted', '==', false)
		.onSnapshot(querySnapshot => {
			const locations = [];
			querySnapshot.forEach(doc => {
				const docData = doc.data();
				locations.push({ ...docData, id: doc.id });
			});
			callback(locations);
		});
};

export const getFlexposLinkWithSyncActiveExists = async syncType => {
	const resp = await firebaseService.callFunctionByName('organisationFlexposLinkGetFlexposLinkWithSyncActiveExists', {
		syncType
	});
	return resp.data;
};

export const getFlexposLinkWithSyncActive = async syncType => {
	const organisationDbRef = firebaseService.getOrganisationRootDB();

	const flexposLinksWithProductSync = await organisationDbRef
		.collection('flexposLinks')
		.where('deleted', '==', false)
		.where(`${syncType}`, '==', true)
		.get();

	if (
		flexposLinksWithProductSync &&
		flexposLinksWithProductSync.docs &&
		flexposLinksWithProductSync.docs.length === 1
	) {
		return flexposLinksWithProductSync.docs[0].data();
	}
	return false;
};

export const createOrganisationUser = async data => {
	const resp = await firebaseService.callFunctionByName('userCreateUser', data);
	return resp.data;
};

export const updateOrganisationUser = async (organisationUid, uid, data) => {
	const resp = await firebaseService.callFunctionByName('userUpdateUser', {
		userUid: uid,
		organisationUid,
		...data
	});
	return resp.data;
};

export const deleteOrganisationUser = async (organisationUid, uid) => {
	const resp = await firebaseService.callFunctionByName('userDeleteUser', {
		userUid: uid,
		organisationUid
	});
	return resp.data;
};

export const sendResetPasswordRequest = async (organisationUid, uid) => {
	const resp = await firebaseService.callFunctionByName('userResetUserPassword', { userUid: uid, organisationUid });
	return resp.data;
};

export const updateOrganisation = (organisationId, data) => {
	const db = firebaseService.getRootDB();
	return db.collection('organisations').doc(organisationId).update(data);
};

export const createFlexposLink = async data => {
	const resp = await firebaseService.callFunctionByName('organisationFlexposLinkCreateFlexposLink', data);
	return resp.data;
};

export const updateFlexposLink = async (organisationUid, uid, data) => {
	const resp = await firebaseService.callFunctionByName('organisationFlexposLinkUpdateFlexposLink', {
		flexposLinkUid: uid,
		organisationUid,
		...data
	});
	return resp.data;
};

export const deleteFlexposLink = async (organisationUid, uid) => {
	const resp = await firebaseService.callFunctionByName('organisationFlexposLinkDeleteFlexposLink', {
		flexposLinkUid: uid,
		organisationUid
	});
	return resp.data;
};

export const setFlexposLinkCustomerSync = async (organisationUid, uid, activateCustomerSync) => {
	const resp = await firebaseService.callFunctionByName('organisationFlexposLinkSetFlexposLinkCustomerSync', {
		flexposLinkUid: uid,
		organisationUid,
		activateCustomerSync
	});
	return resp.data;
};

export const getFlexposClientSettings = async data => {
	const resp = await firebaseService.callFunctionByName('flexposGetClientSettings', data);
	return resp.data;
};

export const callResyncFlexposClientSettings = async data => {
	const resp = await firebaseService.callFunctionByName('flexposForceClientResync', data);
	return resp.data;
};

export const regenerateTokenFlexposLink = async (organisationUid, uid) => {
	const resp = await firebaseService.callFunctionByName('organisationFlexposLinkRegenerateFlexposLinkToken', {
		flexposLinkUid: uid,
		organisationUid
	});
	return resp.data;
};

export const updateContent = async (organisationUid, key, value) => {
	return firebaseService.callFunctionByName('contentUpdateContent', { organisationUid, key, value });
};

export const anonymizeCustomer = async (organisationUid, email, phone, confirm) => {
	const resp = await firebaseService.callFunctionByName('organisationCustomerAnonymizeCustomer', {
		organisationUid,
		email,
		phone,
		confirm
	});
	return resp.data;
};

export default organisationSlice.reducer;

export const selectOrganisation = state => state.organisationApp.organisation;
export const selectContent = state => state.organisationApp.content;
export const selectOrganisationUsers = state => state.organisationApp.users;
export const selectFlexposLinks = state => state.organisationApp.flexposLinks;
export const selectCustomers = state => state.organisationApp.customers;
export const selectLocations = state => state.organisationApp.locations;
