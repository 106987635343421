import OverviewPage from '@ameroservices-platform/loppe-backend/app/main/apps/overview/OverviewPage';

const overviewPageConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: 'apps/overview',
			element: <OverviewPage />,
		},
	],
};

export default overviewPageConfig;
