import { combineReducers } from '@reduxjs/toolkit';
import users from './usersSlice';
import country from './countrySlice';

const sharedReducers = combineReducers({
	users,
	country,
});

export default sharedReducers;
