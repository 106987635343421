import { createSlice } from '@reduxjs/toolkit';
import firebaseService from '@ameroservices-platform/shared/services/firebase';
import orderTypes from '@ameroservices-platform/shared/enums/orderTypes';
import settlementAccountTypes from '@ameroservices-platform/shared/enums/settlementAccountTypes';

const bankPayoutsSlice = createSlice({
	name: 'bankPayoutsApp',
	initialState: {
		organisation: null,
		bankFile: null,
		latestBankFile: null,
		latestGeneratedBankFile: null,
		latestProcessedBankFile: null,
		bankFiles: null,
		bankFileRecords: null,
		bankFileRecord: null,
		bankFileRecordSettlementOrderLines: null,
	},
	reducers: {
		setOrganisation: (state, action) => {
			state.organisation = action.payload;
		},
		setBankFile: (state, action) => {
			state.bankFile = action.payload;
		},
		setLatestBankFile: (state, action) => {
			state.latestBankFile = action.payload;
		},
		setLatestGeneratedBankFile: (state, action) => {
			state.latestGeneratedBankFile = action.payload;
		},
		setLatestProcessedBankFile: (state, action) => {
			state.latestProcessedBankFile = action.payload;
		},
		setBankFiles: (state, action) => {
			state.bankFiles = action.payload;
		},
		setBankFileRecords: (state, action) => {
			state.bankFileRecords = action.payload;
		},
		setBankFileRecord: (state, action) => {
			state.bankFileRecord = action.payload;
		},
		setBankFileRecordSettlementOrderLines: (state, action) => {
			state.bankFileRecordSettlementOrderLines = action.payload;
		},
	},
});

export const {
	setOrganisation,
	setBankFile,
	setLatestBankFile,
	setLatestGeneratedBankFile,
	setLatestProcessedBankFile,
	setBankFiles,
	setBankFileRecords,
	setBankFileRecordSettlementOrderLines,
	setBankFileRecord,
} = bankPayoutsSlice.actions;

export const organisationListener = (organisationUid) => (dispatch) => {
	const db = firebaseService.getRootDB();
	return db
		.collection('organisations')
		.doc(organisationUid)
		.onSnapshot((doc) => {
			dispatch(setOrganisation({ ...doc.data(), id: doc.id }));
		});
};

export const updateBankPayoutsInfo = async (data) => {
	const response = await firebaseService.callFunctionByName('organisationUpdateBankPayoutsInfo', data);
	if (response && response.data) {
		return response.data;
	}
	return false;
};

export const generateBankFile = async (createdBeforeDate) => {
	const response = await firebaseService.callFunctionByName('organisationBankFileGenerateBankFile', {
		createdBeforeDate: createdBeforeDate.getTime(),
	});
	if (response && response.data) {
		return response.data;
	}
	return false;
};

export const handlePayoutProcessed = async (bankFileUid) => {
	const response = await firebaseService.callFunctionByName('organisationBankFileHandlePayoutProcessed', {
		bankFileUid,
	});
	if (response && response.data) {
		return response.data;
	}
	return false;
};

export const handlePayoutUnprocessed = async (bankFileUid) => {
	const response = await firebaseService.callFunctionByName('organisationBankFileHandlePayoutUnprocessed', {
		bankFileUid,
	});
	if (response && response.data) {
		return response.data;
	}
	return false;
};

export const deleteBankFile = async (bankFileUid) => {
	const response = await firebaseService.callFunctionByName('organisationBankFileDeleteBankFile', {
		bankFileUid,
	});
	if (response && response.data) {
		return response.data;
	}
	return false;
};

export const markBankFileAsDownloaded = async (bankFileUid) => {
	const response = await firebaseService.callFunctionByName('organisationBankFileMarkBankFileAsDownloaded', {
		bankFileUid,
	});
	if (response && response.data) {
		return response.data;
	}
	return false;
};

export const bankFileListener = (bankFileUid) => (dispatch) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('bankFiles')
		.doc(bankFileUid)
		.onSnapshot((q) => {
			dispatch(setBankFile({ ...q.data(), id: q.id }));
		});
};

export const latestBankFileListener = () => (dispatch) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('bankFiles')
		.where('deleted', '==', false)
		.orderBy('createdBeforeDate', 'desc')
		.limit(1)
		.onSnapshot((query) => {
			const bankFiles = query.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
			if (bankFiles.length > 0) {
				dispatch(setLatestBankFile(bankFiles[0]));
			} else {
				dispatch(setLatestBankFile(null));
			}
		});
};

export const bankFileRecordListener = (bankFileUid, bankFileRecordUid) => (dispatch) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('bankFiles')
		.doc(bankFileUid)
		.collection('records')
		.doc(bankFileRecordUid)
		.onSnapshot((q) => {
			dispatch(setBankFileRecord({ ...q.data(), id: q.id }));
		});
};

export const latestGeneratedBankFileListener = () => (dispatch) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('bankFiles')
		.where('deleted', '==', false)
		.where('payoutProcessed', '==', false)
		.onSnapshot((query) => {
			const bankFiles = query.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
			if (bankFiles.length === 1) {
				dispatch(setLatestGeneratedBankFile(bankFiles[0]));
			} else {
				dispatch(setLatestGeneratedBankFile(null));
			}
		});
};

export const latestProcessedBankFileListener = () => (dispatch) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('bankFiles')
		.where('deleted', '==', false)
		.where('payoutProcessed', '==', true)
		.orderBy('createdBeforeDate', 'desc')
		.limit(1)
		.onSnapshot((query) => {
			const bankFiles = query.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
			if (bankFiles.length > 0) {
				dispatch(setLatestProcessedBankFile(bankFiles[0]));
			} else {
				dispatch(setLatestProcessedBankFile(null));
			}
		});
};

export const bankFilesListener = () => (dispatch) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('bankFiles')
		.where('deleted', '==', false)
		.onSnapshot((query) => {
			const bankFiles = query.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
			dispatch(setBankFiles(bankFiles));
		});
};

export const bankFileRecordsListener = (bankFileUid) => (dispatch) => {
	const db = firebaseService.getOrganisationRootDB();
	return db
		.collection('bankFiles')
		.doc(bankFileUid)
		.collection('records')
		.where('deleted', '==', false)
		.onSnapshot((query) => {
			const records = query.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
			dispatch(setBankFileRecords(records));
		});
};

export const bankFileRecordSettlementOrderLinesListener = (bankFileRecord) => (dispatch) => {
	const db = firebaseService.getRootDB();
	return db
		.collectionGroup('orderLines')
		.where('organisationUid', '==', bankFileRecord.organisationUid)
		.where('customerUid', '==', bankFileRecord.customerUid)
		.where('deleted', '==', false)
		.where('booked', '==', true)
		.where('orderType', '==', orderTypes.SETTLEMENT)
		.where('orderNumberInt', '>', bankFileRecord.lowestOrderNumberInt)
		.where('settlementAccountType', '==', settlementAccountTypes.PAYOUT_BANK)
		.onSnapshot((query) => {
			const settlementOrderLines = [];
			query.forEach((settlementOrderLineDoc) => {
				const settlementOrderLine = { ...settlementOrderLineDoc.data(), id: settlementOrderLineDoc.id };

				if (bankFileRecord.settlementOrderLineUids.includes(settlementOrderLine.id)) {
					settlementOrderLines.push(settlementOrderLine);
				}
			});
			dispatch(setBankFileRecordSettlementOrderLines(settlementOrderLines));
		});
};

export default bankPayoutsSlice.reducer;

export const selectOrganisation = (state) => state.bankPayoutsApp.organisation;
export const selectBankFile = (state) => state.bankPayoutsApp.bankFile;
export const selectLatestBankFile = (state) => state.bankPayoutsApp.latestBankFile;
export const selectLatestGeneratedBankFile = (state) => state.bankPayoutsApp.latestGeneratedBankFile;
export const selectLatestProcessedBankFile = (state) => state.bankPayoutsApp.latestProcessedBankFile;
export const selectBankFiles = (state) => state.bankPayoutsApp.bankFiles;
export const selectBankFileRecords = (state) => state.bankPayoutsApp.bankFileRecords;
export const selectBankFileRecord = (state) => state.bankPayoutsApp.bankFileRecord;
export const selectBankFileRecordSettlementOrderLines = (state) =>
	state.bankPayoutsApp.bankFileRecordSettlementOrderLines;
