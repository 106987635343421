import React, { lazy } from 'react';
import authRoles from '../../../auth/authRoles';

const BoothBooth = lazy(() => import('./BoothWrapper'));
const BoothsBooths = lazy(() => import('./booths/Booths'));

const BoothsAppConfig = {
	settings: {
		layout: {},
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/apps/booths/:ticketTypeUid',
			element: <BoothBooth />,
		},
		{
			path: '/apps/booths',
			element: <BoothsBooths />,
		},
	],
};

export default BoothsAppConfig;
