import React, { useRef } from 'react';
import PageHeader from '@ameroservices-platform/loppe-backend/app/ui-components/PageHeader';
import TabPage from '@ameroservices-platform/shared/ui-components/TabPage/TabPage';
import WrappedFusePageCarded from '@ameroservices-platform/loppe-backend/app/ui-components/WrappedFusePageCarded';
import bankPayoutsReducer from '@ameroservices-platform/loppe-backend/app/main/apps/bankPayouts/bankPayoutsSlice';
import withReducer from '@ameroservices-platform/loppe-backend/app/store/withReducer';
import BankFileRecordSettlementOrderLines from '@ameroservices-platform/loppe-backend/app/main/apps/bankPayouts/bankFile/bankFileRecord/tabs/bankFileRecordSettlementOrderLines/bankFileRecordSettlementOrderLines';

function BankFileRecord() {
	const pageLayout = useRef(null);

	return (
		<TabPage
			wrappedFusePageCarded={WrappedFusePageCarded}
			ref={pageLayout}
			header={<PageHeader title={'Bankfil postering'} icon={'text_snippet'} goBack />}
			tabs={[
				{
					title: 'Bank udbetalingslinjer',
					element: <BankFileRecordSettlementOrderLines />,
					order: 0,
				},
			]}
			innerScroll
		/>
	);
}

export default withReducer('bankPayoutsApp', bankPayoutsReducer)(BankFileRecord);
