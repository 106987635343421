import { lazy } from 'react';

const DashboardPage = lazy(() => import('./DashboardPage'));

const DashboardPageConfig = {
	settings: {
		layout: {
			config: {},
		},
	},
	routes: [
		{
			path: 'apps/dashboard',
			element: <DashboardPage />,
		},
	],
};

export default DashboardPageConfig;
