import React from 'react';
import authRoles from '../../../auth/authRoles';

const NewSubscriptionNewSubscription = React.lazy(() => import('./newSubscription/NewSubscription'));
const SubscriptionSubscription = React.lazy(() => import('./subscription/Subscription'));
const SubscriptionsSubscriptions = React.lazy(() => import('./subscriptions/Subscriptions'));

const BoothSubscriptionsAppConfig = {
	settings: {
		layout: {},
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/apps/booth-subscriptions/new/:customerUid/:subscriptionUid',
			element: <NewSubscriptionNewSubscription />,
		},
		{
			path: '/apps/booth-subscriptions/new',
			element: <NewSubscriptionNewSubscription />,
		},
		{
			path: '/apps/booth-subscriptions/details/:customerUid/:subscriptionUid',
			element: <SubscriptionSubscription />,
		},
		{
			path: '/apps/booth-subscriptions',
			element: <SubscriptionsSubscriptions />,
		},
	],
};

export default BoothSubscriptionsAppConfig;
