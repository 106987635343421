import Hidden from '@mui/material/Hidden';
import { styled } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useDispatch, useSelector } from 'react-redux';
import { navbarCloseMobile } from '@ameroservices-platform/loppe-backend/app/store/fuse/navbarSlice';
import NavbarStyle1Content from './NavbarStyle1Content';
import { useState } from 'react';

const StyledNavBar = styled('div')(({ theme, open, position, navbarwidth }) => ({
	minWidth: navbarwidth,
	width: navbarwidth,
	maxWidth: navbarwidth,
	...(!open && {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.leavingScreen,
		}),
		...(position === 'left' && {
			marginLeft: `-${navbarwidth}px`,
		}),
		...(position === 'right' && {
			marginRight: `-${navbarwidth}px`,
		}),
	}),
	...(open && {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const StyledNavBarMobile = styled(SwipeableDrawer)(({ theme, navbarwidth }) => ({
	'& .MuiDrawer-paper': {
		minWidth: navbarwidth,
		width: navbarwidth,
		maxWidth: navbarwidth,
	},
}));

function NavbarStyle1(props) {
	const dispatch = useDispatch();
	const config = useSelector(({ fuse }) => fuse.settings.current.layout.config);
	const navbar = useSelector(({ fuse }) => fuse.navbar);
	const [navbarWidth, setNavbarWidth] = useState(320);

	return (
		<>
			<Hidden lgDown>
				<StyledNavBar
					className="flex-col flex-auto sticky top-0 overflow-hidden h-screen shrink-0 z-20 shadow-5"
					open={navbar.open}
					position={config.navbar.position}
					navbarwidth={navbarWidth}
				>
					<NavbarStyle1Content
						toggleLargeNav={() => {
							setNavbarWidth((oldState) => {
								if (oldState === 320) {
									return 500;
								}
								return 320;
							});
						}}
					/>
				</StyledNavBar>
			</Hidden>

			<Hidden lgUp>
				<StyledNavBarMobile
					classes={{
						paper: 'flex-col flex-auto h-full',
					}}
					anchor={config.navbar.position}
					variant="temporary"
					open={navbar.mobileOpen}
					onClose={() => dispatch(navbarCloseMobile())}
					onOpen={() => {}}
					disableSwipeToOpen
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					navbarwidth={navbarWidth}
				>
					<NavbarStyle1Content
						toggleLargeNav={() => {
							setNavbarWidth((oldState) => {
								if (oldState === 320) {
									return 500;
								}
								return 320;
							});
						}}
					/>
				</StyledNavBarMobile>
			</Hidden>
		</>
	);
}

export default NavbarStyle1;
