import FuseUtils from '@ameroservices-platform/shared/fuse/utils';
import AppContext from '@ameroservices-platform/loppe-backend/app/AppContext';
import { Component } from 'react';
import { connect } from 'react-redux';
import { matchRoutes } from 'react-router-dom';
import withRouter from '@ameroservices-platform/loppe-backend/fuse/core/withRouter';
import settingsConfig from '@ameroservices-platform/loppe-backend/app/fuse-configs/settingsConfig';

class FuseAuthorization extends Component {
	constructor(props, context) {
		super(props);
		const { routes } = context;
		this.state = {
			accessGranted: true,
			routes,
		};
		this.defaultLoginRedirectUrl = settingsConfig.loginRedirectUrl || '/';
	}

	componentDidMount() {
		if (!this.state.accessGranted) {
			this.redirectRoute();
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		return nextState.accessGranted !== this.state.accessGranted;
	}

	componentDidUpdate() {
		if (!this.state.accessGranted) {
			this.redirectRoute();
		}
	}

	static getDerivedStateFromProps(props, state) {
		const { location, userRole } = props;
		const { pathname } = location;

		const matchedRoutes = matchRoutes(state.routes, pathname);

		const matched = matchedRoutes ? matchedRoutes[0] : false;

		return {
			accessGranted: matched ? FuseUtils.hasPermission(matched.route.auth, userRole) : true,
		};
	}

	redirectRoute() {
		const { location, userRole, navigate } = this.props;
		const { pathname } = location;
		const loginRedirectUrl = settingsConfig.loginRedirectUrl
			? settingsConfig.loginRedirectUrl
			: this.defaultLoginRedirectUrl;

		/*
        User is guest
        Redirect to Login Page
        */
		if (!userRole || userRole.length === 0) {
			setTimeout(
				() =>
					navigate({
						pathname: '/login',
					}),
				0
			);
			settingsConfig.loginRedirectUrl = pathname;
		} else {
			/*
        User is member
        User must be on unAuthorized page or just logged in
        Redirect to dashboard or loginRedirectUrl
        */
			setTimeout(
				() =>
					navigate({
						pathname: loginRedirectUrl,
					}),
				0
			);
			settingsConfig.loginRedirectUrl = this.defaultLoginRedirectUrl;
		}
	}

	render() {
		// console.info('Fuse Authorization rendered', this.state.accessGranted);
		return this.state.accessGranted ? <>{this.props.children}</> : null;
	}
}

function mapStateToProps({ auth }) {
	return {
		userRole: auth.user.role,
	};
}

FuseAuthorization.contextType = AppContext;

export default withRouter(connect(mapStateToProps)(FuseAuthorization));
